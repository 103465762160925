import { useReducer } from 'react';

export const LOAD_START_ACTION_HOOK = 'startLoading';
export const LOAD_END_ACTION_HOOK = 'endLoading';

const initialState = {
  loading: true,
  data: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case LOAD_START_ACTION_HOOK:
      return { ...state, loading: true };
    case LOAD_END_ACTION_HOOK:
      return { ...state, loading: false, data: action.data };
    default:
      return state;
  }
};

export default function useDataLoadingState() {
  return useReducer(reducer, initialState);
}
