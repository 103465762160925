import { FirestoreCollection, FirestoreMutation } from '@react-firebase/firestore';
import React, { useCallback, useState } from 'react';
import { Button, Modal, notification, PageHeader } from 'antd';
import { useParams, useHistory } from 'react-router-dom';
import CompanyDetail from './CompanyDetail';
import CompaniesList from './CompaniesList';
import useModal from '../../hooks/useModal';
import CreateCompanyForm from './CreateCompanyForm';
import { IsSuperAdmin } from '../../components/IsSuperAdmin';

export default function CompaniesPage() {
  const { companyId } = useParams();
  const history = useHistory();

  const goBack = useCallback(() => history.goBack(), [history]);

  const [isOpen, { close, open }] = useModal();
  const [isCreating, setIsCreating] = useState(false);

  if (companyId) {
    return <CompanyDetail goBack={goBack} companyId={companyId} />;
  }

  return (
    <>
      <PageHeader
        title="Aziende"
        extra={[
          <IsSuperAdmin>
            <Button type="primary" onClick={open} key="add">
              Aggiungi
            </Button>
          </IsSuperAdmin>,
        ]}
      />
      <FirestoreCollection path="/companies">{d => <CompaniesList {...d} />}</FirestoreCollection>
      <Modal visible={isOpen} onCancel={close} footer={null} title="Crea nuova Azienda">
        <FirestoreMutation type="add" path="/companies">
          {({ runMutation }) => (
            <CreateCompanyForm
              loading={isCreating}
              onSubmit={async data => {
                try {
                  setIsCreating(true);
                  await runMutation(data);
                  setIsCreating(false);
                  close();
                } catch (e) {
                  console.log(e);
                  notification.error({ message: "c'è stato un errore" });
                }
              }}
            />
          )}
        </FirestoreMutation>
      </Modal>
    </>
  );
}
