import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';
import { Button, Modal, Select, notification } from 'antd';
import find from 'lodash/find';
import { useParams } from 'react-router-dom';
import useFirmwares from '../../hooks/useFirmwares';
import ObjectDescription from '../../components/ObjectDescription';
import useUpdateFirmware from '../../hooks/useUpdateFirmware';

const selectStyle = { width: 120 };

export default function DeviceUpdateFirmwareModal({ isOpen, close }) {
  const [selectedVersion, setSelectedVersion] = useState('');
  const [firmwares] = useFirmwares();
  const [loading, updateFirmware] = useUpdateFirmware();
  const onUpdateStart = useCallback(() => {
    notification.info({ message: "L'aggiornamento del dispositivo è iniziato" });
    close();
  }, [close]);

  const { deviceId } = useParams();

  const selected = useMemo(() => find(firmwares, f => f.id === selectedVersion), [
    firmwares,
    selectedVersion,
  ]);

  return (
    <Modal title="Aggiorna firmware" visible={isOpen} closable onCancel={close} footer={null}>
      <p>
        <strong>Seleziona la versione</strong>
      </p>
      <Select style={selectStyle} value={selectedVersion} onChange={setSelectedVersion}>
        {firmwares.map(f => (
          <Select.Option key={f.id} value={f.id}>
            {f.versionNumber}
          </Select.Option>
        ))}
      </Select>
      {selectedVersion && (
        <>
          <p className="mt-2">
            <strong>Dettagli versione</strong>
          </p>
          <ObjectDescription object={selected} excludeList={['id', 'fileUpload']} />
          <Button
            type="primary"
            loading={loading}
            onClick={() => updateFirmware(deviceId, selectedVersion, onUpdateStart)}
          >
            Aggiorna a questa versione
          </Button>
        </>
      )}
    </Modal>
  );
}

DeviceUpdateFirmwareModal.propTypes = {
  close: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
};

DeviceUpdateFirmwareModal.defaultProps = {
  isOpen: false,
};
