import PropTypes from 'prop-types';
import React from 'react';
import { Route } from 'react-router-dom';
import { Card, Layout, Typography } from 'antd';

function AdminDomainRoute(props) {
  const isAdminDomain = !!window.location.hostname.match(process.env.REACT_APP_DOMAIN);

  if (isAdminDomain) {
    return <Route {...props} />;
  }

  // eslint-disable-next-line react/prop-types
  const { component, render, ...valid } = props;

  return (
    <Route
      {...valid}
      render={() => (
        <Layout style={{ height: '100vh' }}>
          <Layout.Content
            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          >
            <Card style={{ maxWidth: '40em', margin: 20, textAlign: 'center' }}>
              <Typography.Title level={3}>Accesso bloccato</Typography.Title>
              <p>L&apos;accesso non è permesso da questo dominio</p>
            </Card>
          </Layout.Content>
        </Layout>
      )}
    />
  );
}

export default AdminDomainRoute;

AdminDomainRoute.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }),
};

AdminDomainRoute.defaultProps = {
  location: null,
};
