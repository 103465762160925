import { useEffect, useReducer } from 'react';
import firebase from '../config';

const initialState = {
  loading: false,
  data: null,
};
const reducer = (state, action) => {
  switch (action.type) {
    case 'startLoading':
      return { ...state, loading: true };
    case 'endLoading':
      return { ...state, loading: false, data: action.data };
    default:
      return state;
  }
};

function useCompaniesOfUser(userId) {
  const [state, dispatch] = useReducer(reducer, initialState);

  // const ref = useMemo(() => getRef(companyId), [companyId]);

  useEffect(() => {
    // if (ref) {
    async function queryUsersCompanies() {
      const snapshot = await firebase
        .firestore()
        .collection('users')
        .doc(userId)
        .collection('userCompanyMembership')
        .get();

      const companies = await Promise.all(
        snapshot.docs.map(async docRef => {
          const company = await docRef.data().company.get();
          return {
            ...company.data(),
            id: company.id,
            role: docRef.data().role,
          };
        }),
      );

      dispatch({ type: 'endLoading', data: companies });
    }
    dispatch({ type: 'startLoading' });
    if (userId) {
      queryUsersCompanies();
    }
  }, []);

  return state;
}

export default useCompaniesOfUser;
