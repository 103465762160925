import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { Button, Divider, Form, Icon, Input, notification } from 'antd';
import { auth } from '../../config';
import GoogleAuth from './GoogleAuth';

function LoginForm({ form, onSuccess }) {
  const handleSubmit = useCallback(
    e => {
      e.preventDefault();
      form.validateFields(async (err, { email, password }) => {
        if (!err) {
          try {
            await auth().signInWithEmailAndPassword(email, password);
            onSuccess();
          } catch (error) {
            notification.error({
              message: 'Errore',
              description: error.message,
            });
          }
        }
      });
    },
    [form, onSuccess],
  );

  const { getFieldDecorator } = form;
  return (
    <Form onSubmit={handleSubmit} className="login-form">
      <Form.Item>
        {getFieldDecorator('email', {
          rules: [{ required: true, message: 'Inserisci il tuo indirizzo email' }],
        })(<Input prefix={<Icon type="user" style={{ fontSize: 13 }} />} placeholder="Email" />)}
      </Form.Item>

      <Form.Item>
        {getFieldDecorator('password', {
          rules: [{ required: true, message: 'Inserisci la password' }],
        })(
          <Input
            prefix={<Icon type="lock" style={{ fontSize: 13 }} />}
            type="password"
            placeholder="Password"
          />,
        )}
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" className="login-form-button">
          Accedi
        </Button>
      </Form.Item>

      <Divider>Oppure</Divider>
      <Form.Item>
        <GoogleAuth onSuccess={onSuccess} />
      </Form.Item>
    </Form>
  );
}

export default Form.create()(LoginForm);

LoginForm.propTypes = {
  form: PropTypes.shape({
    validateFields: PropTypes.func,
    getFieldDecorator: PropTypes.func,
  }).isRequired,
  onSuccess: PropTypes.func.isRequired,
};
