import React, { useContext } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import PermissionsContext from '../../contexts/Permissions';
import { ADMIN } from '../../constants';
import TechnicianLayout from './Layout';

export default function TechnicianHomePage() {
  const permissions = useContext(PermissionsContext);

  if (permissions === null || !permissions.length) {
    return <Redirect to="/" />;
  }

  let redirectTo = null;
  const admins = permissions.filter(r => r.role === ADMIN);
  if (admins.length) {
    redirectTo = admins[0].id;
  } else {
    redirectTo = permissions[0].id;
  }

  return (
    <Switch>
      <Route exact path="/app" render={() => <Redirect to={`/app/${redirectTo}/`} />} />
      <Route path="/app/:companyId/" component={TechnicianLayout} />
    </Switch>
  );
}
