import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Layout, Card, notification } from 'antd';
import qs from 'query-string';
import LoginForm from './LoginForm';
import { auth } from '../../config';
import SpinnerPage from '../../components/SpinnerPage';
import logoSrc from '../../assets/logo.png';

function LoginPage() {
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const location = useLocation();
  const nextIsGoogle =
    location.search &&
    qs.parse(location.search) &&
    qs.parse(location.search).next.includes('authorizeGoogleHome');

  const onSuccess = useCallback(() => {
    if (location.search) {
      const queryString = qs.parse(location.search);
      history.replace(queryString.next);
    } else {
      history.replace('/');
    }
  }, [history, location]);

  useEffect(() => {
    async function getRedirect() {
      try {
        const result = await auth().getRedirectResult();
        console.log(result);
        if (result.user) {
          if (sessionStorage.getItem('next')) {
            history.replace(sessionStorage.getItem('next'));
          } else {
            history.replace('/');
          }
        }
      } catch (e) {
        notification.error({ message: e.message });
      } finally {
        sessionStorage.clear();
        setLoading(false);
      }
    }

    getRedirect();
  }, []);

  if (loading) {
    return <SpinnerPage />;
  }

  return (
    <Layout style={{ height: '100vh' }}>
      <Layout.Content style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Card style={{ maxWidth: '40em', margin: 20 }}>
          <img
            src={logoSrc}
            alt="logo"
            style={{ display: 'block', margin: '0 auto 1rem auto', maxWidth: '100%' }}
          />
          {nextIsGoogle && (
            <p style={{ textAlign: 'center' }}>
              Accedi per procedere ad autorizzare Google ad accedere e controllare i tuoi
              dispositivi. Verrà visualizzata una schermata di conferma dopo il login.
            </p>
          )}
          <LoginForm onSuccess={onSuccess} />
        </Card>
      </Layout.Content>
    </Layout>
  );
}

export default LoginPage;
