import PropTypes from 'prop-types';
import { Icon, Table } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import { getIconColor } from '../../utils/isOnline';

export default function DevicesList({ isLoading, value }) {
  const items = value || [];
  return (
    <Table
      loading={isLoading}
      dataSource={items}
      rowKey="id"
      itemLayout="horizontal"
      columns={[
        {
          title: 'Connessione',
          render: (_, record) => (
            <Icon
              type="cloud"
              theme="twoTone"
              style={{ fontSize: '1.6rem', marginRight: '.5rem' }}
              twoToneColor={getIconColor(record.connectionStatus)}
            />
          ),
        },
        {
          title: 'MAC',
          dataIndex: 'macAddress',
        },
        {
          title: 'Nome',
          dataIndex: 'label',
        },
        {
          title: 'Modello',
          dataIndex: 'model',
        },
        {
          title: 'Versione',
          dataIndex: 'hwVer',
        },
        {
          title: 'Azioni',
          render: (_, record) => <Link to={`${record.id}`}>vedi Dispositivo</Link>,
        },
      ]}
    />
  );
}

DevicesList.propTypes = {
  isLoading: PropTypes.bool,
  value: PropTypes.arrayOf(PropTypes.shape({})),
};

DevicesList.defaultProps = {
  value: [],
  isLoading: true,
};
