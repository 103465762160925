export default function isOnline(timestamp) {
  return timestamp && timestamp.toMillis() >= new Date().getTime() - 5 * 60 * 1000;
}

export function getIconColor(status) {
  switch (status) {
    case 'online':
      return '#52c41a';
    case 'offline':
      return '#f5222d';
    default:
      return '#fadb14';
  }
}
