import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { Button, Icon, notification } from 'antd';

import qs from 'query-string';
import { useLocation } from 'react-router-dom';
import { auth } from '../../config';

export default function GoogleAuth({ onSuccess }) {
  const location = useLocation();

  const onLogin = useCallback(async () => {
    try {
      const queryString = qs.parse(location.search);
      window.sessionStorage.setItem('next', queryString.next);
      const provider = new auth.GoogleAuthProvider();
      await auth().signInWithRedirect(provider);
    } catch (e) {
      notification.error({ message: e.message });
    }
  }, [onSuccess]);

  return (
    <Button onClick={onLogin} style={{ display: 'block', margin: 'auto' }}>
      <Icon type="google" /> Accedi con Google
    </Button>
  );
}

GoogleAuth.propTypes = {
  onSuccess: PropTypes.func.isRequired,
};
