import { useCallback } from 'react';
import { notification } from 'antd';
import callHttpsFunction from '../utils/functions';

export default function useSendNotification() {
  return useCallback(async ({ message, topic }) => {
    try {
      const result = await callHttpsFunction('notificationSendBroadcastPush', { message, topic });
      console.log(result);
      notification.success({ message: 'Notifica inviata' });
    } catch (e) {
      console.log(e);
      notification.error({ message: e.message });
    }
  });
}
