import { Menu, Icon } from 'antd';
import React from 'react';
import { Link, Switch } from 'react-router-dom';

import DashboardPage from '../../pages/Dashboard';
import UsersPage from '../../pages/Users';
import DevicePage from '../../pages/Devices';
import CompaniesPage from '../../pages/Companies';
import FirmwareUpload from '../../pages/FirmwareUpload';
import SuperAdminRoute from '../../components/SuperAdminRoute';
import NotificationPage from '../../pages/Notification';

import DefaultLayout from '../../components/Layout';
import { IsSuperAdmin } from '../../components/IsSuperAdmin';

export default function AdminHome() {
  return (
    <IsSuperAdmin>
      {' '}
      <DefaultLayout
        menuItems={[
          <Menu.Item key="1">
            <Icon type="dashboard" />
            <span>Dashboard</span>
            <Link to="/admin" />
          </Menu.Item>,
          <Menu.Item key="2-1">
            <Icon type="robot" />
            <span>Dispositivi</span>
            <Link to="/admin/devices/" />
          </Menu.Item>,
          <Menu.Item key="3">
            <Icon type="team" />
            <span>Utenti</span>
            <Link to="/admin/users/" />
          </Menu.Item>,
          <Menu.Item key="4">
            <Icon type="bank" />
            <span>Aziende</span>
            <Link to="/admin/companies/" />
          </Menu.Item>,
          <Menu.Item key="5">
            <Icon type="bell" />
            <span>Notifiche Push</span>
            <Link to="/admin/notifications" />
          </Menu.Item>,
          <Menu.Item key="6">
            <Icon type="upload" />
            <span>Firmware</span>
            <Link to="/admin/firmware" />
          </Menu.Item>,
        ]}
      >
        <Switch>
          <SuperAdminRoute exact path="/admin" component={DashboardPage} />
          <SuperAdminRoute path="/admin/users/" component={UsersPage} />
          <SuperAdminRoute path="/admin/devices/" component={DevicePage} />
          <SuperAdminRoute path="/admin/companies/" exact component={CompaniesPage} />
          <SuperAdminRoute path="/admin/companies/:companyId" component={CompaniesPage} />
          <SuperAdminRoute path="/admin/firmware" component={FirmwareUpload} />
          <SuperAdminRoute path="/admin/notifications" component={NotificationPage} />
        </Switch>
      </DefaultLayout>
    </IsSuperAdmin>
  );
}
