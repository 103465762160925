import PropTypes from 'prop-types';
import React, { useContext, useMemo } from 'react';
import { Layout, Menu, Icon, Avatar, Drawer, Button, Typography } from 'antd';

import { auth } from '../config';
import { IsSuperAdmin } from './IsSuperAdmin';
import useLogout from '../hooks/useLogout';
import useToggle from '../hooks/useToggle';
import useCurrentCompany from '../hooks/useCurrentCompany';
import PermissionsContext from '../contexts/Permissions';

const { Header, Sider, Content } = Layout;

function getUser() {
  return auth().currentUser.email;
}

export default function DefaultLayout({ menuItems, children, extraDrawer }) {
  const [drawer, toggleDrawer] = useToggle();
  const [menu, toggleMenu] = useToggle();

  const currentCompany = useCurrentCompany();
  const permissions = useContext(PermissionsContext);
  const logout = useLogout();
  const user = useMemo(getUser, []);

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sider collapsible collapsed={menu} onCollapse={toggleMenu}>
        <div className="logo">TLab IoT</div>
        <Menu theme="dark" mode="inline">
          {menuItems}
        </Menu>
      </Sider>
      <Layout>
        <Header
          style={{
            background: '#fff',
            padding: '0 24px',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {currentCompany && (
            <>
              <Typography.Title className="companyTitle" level={4}>
                {currentCompany.name}
              </Typography.Title>
              {permissions.length > 1 && (
                <Button type="link" onClick={toggleDrawer}>
                  Cambia
                </Button>
              )}
            </>
          )}
          <div style={{ marginLeft: 'auto' }}>
            <Button onClick={toggleDrawer} type="link">
              <Avatar>{user.charAt(0)}</Avatar>
              <span className="ml-2">{user}</span>
            </Button>
          </div>
        </Header>
        <Content
          style={{
            margin: '24px 16px',
            padding: 24,
            background: '#fff',
          }}
        >
          {children}
        </Content>
        <Drawer title="" placement="right" closable onClose={toggleDrawer} visible={drawer}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'top',
              alignItems: 'center',
              flexDirection: 'column',
              marginTop: 40,
            }}
          >
            <Avatar size={40}>{user.charAt(0)}</Avatar>
            <span>{user}</span>
            <IsSuperAdmin>
              <span>
                <Icon type="crown" /> Super Amministratore
              </span>
            </IsSuperAdmin>
            <Button style={{ marginTop: '2rem' }} onClick={logout}>
              Esci
            </Button>
            {extraDrawer}
          </div>
        </Drawer>
      </Layout>
    </Layout>
  );
}

DefaultLayout.propTypes = {
  children: PropTypes.node,
  menuItems: PropTypes.node,
  extraDrawer: PropTypes.node,
};

DefaultLayout.defaultProps = {
  children: null,
  menuItems: null,
  extraDrawer: null,
};
