import { useEffect } from 'react';
import semverSort from 'semver-sort';
import find from 'lodash/find';
import useDataLoadingState from './useDataLoadingState';
import { LOAD_END_ACTION_HOOK, LOAD_START_ACTION_HOOK } from '../constants';
import firebase from '../config';

async function getFirmwares(dispatch) {
  const query = await firebase
    .firestore()
    .collection('firmwares')
    .get();

  let firmwares = query.docs.map(docRef => ({
    ...docRef.data(),
    id: docRef.id,
  }));

  // Force semantic versioning order
  const sortOrder = semverSort.desc(firmwares.map(f => f.versionNumber));
  firmwares = sortOrder.map(vn => find(firmwares, f => f.versionNumber === vn));

  dispatch({ type: LOAD_END_ACTION_HOOK, data: firmwares });
}

export default function useFirmwares() {
  const [state, dispatch] = useDataLoadingState();

  useEffect(() => {
    dispatch({ type: LOAD_START_ACTION_HOOK });
    getFirmwares(dispatch);
  }, []);

  return [state.data, state.isLoading];
}
