import React, { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { Button, Card, Layout, notification, Typography } from 'antd';
import qs from 'query-string';
import { auth } from '../../config';

import ga from '../../assets/tido-google.png';
import callHttpsFunction from '../../utils/functions';

export default function AuthorizeGoogleHomeAssistant() {
  const location = useLocation();
  const { uid, email } = auth().currentUser;

  const authorize = useCallback(async () => {
    const params = qs.parse(location.search);
    const { state, redirect_uri: redirectUri = '' } = params;

    try {
      const { result } = await callHttpsFunction('OAuth2AuthorizeUser', params);

      window.location = `${redirectUri}?code=${result.code}&state=${state}`;
    } catch (e) {
      notification.error({ message: 'Errore nel generare il token' });
    }
  }, [location, uid]);

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Layout.Content style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Card style={{ maxWidth: '40em', margin: 20 }}>
          <img
            src={ga}
            alt="google assistant logo"
            style={{ maxWidth: '25rem', margin: 'auto', display: 'block' }}
          />
          <Typography.Title level={4} style={{ marginTop: '2rem' }}>
            Vuoi collegare il tuo account Ti-Do con Google?
          </Typography.Title>
          <div align="center" style={{ marginBottom: '2rem' }}>
            <i>Confermando, autorizzi Google ad accedere e controllare i tuoi dispositivi.</i>
          </div>
          <div align="center">Sei collegato come {email}</div>
          <div style={{ marginTop: '1rem' }}>
            <Button type="primary" onClick={authorize} style={{ margin: 'auto', display: 'block' }}>
              Autorizza
            </Button>
          </div>
        </Card>
      </Layout.Content>
    </Layout>
  );
}
