import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { Descriptions, PageHeader, Spin } from 'antd';
import { FirestoreDocument } from '@react-firebase/firestore';
import { useParams, useHistory } from 'react-router-dom';

const UserData = ({ value }) => {
  const history = useHistory();

  const goBack = useCallback(() => history.goBack(), [history]);

  if (!value) return null;
  return (
    <>
      <PageHeader title={`Utente: ${value.email}`} onBack={goBack} />
      <Descriptions>
        {Object.keys(value).map(k => (
          <Descriptions.Item key={k} label={k}>
            {value[k] && value[k].toString()}
          </Descriptions.Item>
        ))}
      </Descriptions>
    </>
  );
};

export default function UserDetail() {
  const { userId } = useParams();

  return (
    <FirestoreDocument path={`/users/${userId}`}>
      {d => (d.isLoading ? <Spin /> : <UserData {...d} />)}
    </FirestoreDocument>
  );
}

UserData.propTypes = {
  value: PropTypes.shape(),
};

UserData.defaultProps = {
  value: null,
};
