export default function whereCompanyDevices(firebase, companyId, opName = 'operator') {
  return {
    field: 'authorizedSupportCompany',
    [opName]: '==',
    value: firebase
      .firestore()
      .collection('companies')
      .doc(companyId),
  };
}
