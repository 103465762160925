import React, { useMemo, useState } from 'react';
import { Col, Icon, Input, PageHeader, Row, Select } from 'antd';
import UsersList from './UsersList';
import useCollectionWhere from '../../hooks/useCollectionWhere';

export default function UsersListPage() {
  const [query, setQuery] = useState({
    search: '',
    field: 'email',
  });
  const where = useMemo(() => {
    if (!query.search) {
      return [];
    }

    const strlength = query.search.length;
    const strFrontCode = query.search.slice(0, strlength - 1);
    const strEndCode = query.search.slice(strlength - 1, query.search.length);
    const endcode = strFrontCode + String.fromCharCode(strEndCode.charCodeAt(0) + 1);

    return [
      {
        field: query.field,
        op: '>=',
        value: query.search,
      },
      {
        field: query.field,
        op: '<',
        value: endcode,
      },
    ];
  }, [query]);
  const { data, loading } = useCollectionWhere('users', null, where);

  return (
    <>
      <PageHeader title="Utenti" />
      <Row type="flex" align="middle" gutter={16} style={{ marginBottom: '1rem' }}>
        <Col>
          <Select defaultValue={query.field} onChange={v => setQuery({ ...query, field: v })}>
            <Select.Option value="email">Email</Select.Option>
            <Select.Option value="displayName">Nome</Select.Option>
          </Select>
        </Col>
        <Col>
          <Input
            placeholder="Cerca"
            prefix={<Icon type="search" />}
            value={query.search}
            onChange={e => setQuery({ ...query, search: e.target.value })}
          />
        </Col>
      </Row>
      <UsersList isLoading={loading} value={data} />
    </>
  );
}
