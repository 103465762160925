import React from 'react';
import ReactDOM from 'react-dom';

import './styles/index.css';
import 'antd/dist/antd.css';

import App from './App';

if (process.env.NODE_ENV !== 'production') {
  // eslint-disable-next-line global-require
  const whyDidYouRender = require('@welldone-software/why-did-you-render');
  whyDidYouRender(React);
}

ReactDOM.render(<App />, document.getElementById('root'));
