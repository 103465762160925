import { useCallback, useState } from 'react';

function useModal() {
  const [state, setState] = useState(false);
  const close = useCallback(() => setState(false), []);
  const open = useCallback(() => setState(true), []);
  const toggle = useCallback(() => setState(!state), [state]);

  return [state, { open, close, toggle }];
}

export default useModal;
