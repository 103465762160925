import * as firebase from 'firebase/app';
import 'firebase/functions';

export const firebaseConfig = {
  apiKey: 'AIzaSyAxAS49dIf9iM9wh-TdyAeq_qGIDIvNZc0',
  authDomain: 'tlab-iot.firebaseapp.com',
  databaseURL: 'https://tlab-iot.firebaseio.com',
  projectId: 'tlab-iot',
  storageBucket: 'tlab-iot.appspot.com',
  messagingSenderId: '311364457303',
  appId: '1:311364457303:web:2cd9a6893a7918db40a412',
};

firebase.initializeApp(firebaseConfig);

export default firebase;
export const { auth } = firebase;
