import React, { useState } from 'react';
import { PageHeader } from 'antd';
import NotificationsForm from './NotificationsForm';
import useSendNotification from '../../hooks/useSendNotification';

export default function NotificationPage() {
  const [uploading] = useState(false);

  const onSubmit = useSendNotification();
  return (
    <>
      <PageHeader title="Invio Notifica" />
      <NotificationsForm onSubmit={onSubmit} loading={uploading} />
    </>
  );
}
