import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button, Modal, Input } from 'antd';
import { useParams } from 'react-router-dom';
import useCloneDevice from '../../hooks/useCloneDevice';

const selectStyle = { width: '100%', marginBottom: '1rem' };

export default function DeviceCloneModal({ isOpen, close }) {
  const [text, changeText] = useState('');
  const { deviceId } = useParams();

  const clone = useCloneDevice(deviceId, text, close);

  return (
    <Modal title="Clona dispositivo" visible={isOpen} closable onCancel={close} footer={null}>
      <p>
        <strong>Inserisci il codice del dispositivo su cui vuoi clonare</strong>
      </p>
      <Input
        value={text}
        onChange={e => changeText(e.target.value)}
        style={selectStyle}
        placeholder="ID Dispositivo"
      />
      <Button onClick={clone} disabled={!text}>
        Clona
      </Button>
    </Modal>
  );
}

DeviceCloneModal.propTypes = {
  close: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
};

DeviceCloneModal.defaultProps = {
  isOpen: false,
};
