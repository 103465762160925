import { useCallback, useState } from 'react';
import { notification } from 'antd';
import callHttpsFunction from '../utils/functions';
import emptyFn from '../utils/emptyFn';

export default function useUpdateFirmware() {
  const [state, setState] = useState(false);
  const updateDevice = useCallback(async (deviceId, version, onStart = emptyFn) => {
    try {
      setState(true);
      onStart();
      await callHttpsFunction('deviceSendFirmwareUpdate', {
        deviceId,
        newFwVersion: version,
      });
      notification.success({ message: 'Aggiornamento del dispositivo terminata' });
    } catch (e) {
      notification.error({ message: e.message });
    } finally {
      setState(false);
    }
  });

  return [state, updateDevice];
}
