import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { Form, Button, Input, Modal } from 'antd';
import useUpdateCompany from '../../hooks/useUpdateCompany';

function CompanyUpdateForm({ form, onSubmit, loading, company, close }) {
  const handleSubmit = useCallback(
    e => {
      e.preventDefault();
      form.validateFields((err, values) => {
        if (!err) {
          // eslint-disable-next-line react/prop-types
          onSubmit(company.__id, values);
          close();
        }
      });
    },
    [form, onSubmit],
  );

  const { getFieldDecorator } = form;
  const formItemLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 14 },
  };
  return (
    <Form {...formItemLayout} onSubmit={handleSubmit}>
      <Form.Item label="Nome">
        {getFieldDecorator('name', {
          initialValue: company.name,
          rules: [{ required: true }],
        })(<Input />)}
      </Form.Item>

      <Form.Item label="Sito internet">
        {getFieldDecorator('website', {
          initialValue: company.website,
        })(<Input />)}
      </Form.Item>

      <Form.Item label="Nome">
        {getFieldDecorator('info', {
          initialValue: company.info,
        })(<Input.TextArea rows={4} />)}
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" loading={loading}>
          Modifica
        </Button>
      </Form.Item>
    </Form>
  );
}

const CompanyUpdateFormWrapper = Form.create()(CompanyUpdateForm);

export default function CompanyUpdateModal({ isOpen, close, company }) {
  const updateCompany = useUpdateCompany();

  return (
    <Modal visible={isOpen} title="Title" onCancel={close} footer={null}>
      <CompanyUpdateFormWrapper company={company} onSubmit={updateCompany} close={close} />
    </Modal>
  );
}

CompanyUpdateForm.propTypes = {
  form: PropTypes.shape({
    validateFields: PropTypes.func,
    getFieldDecorator: PropTypes.func,
  }).isRequired,
  close: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  company: PropTypes.shape({
    name: PropTypes.string,
    __id: PropTypes.string,
    website: PropTypes.string,
    info: PropTypes.string,
  }).isRequired,
};

CompanyUpdateModal.propTypes = {
  close: PropTypes.func.isRequired,
  company: PropTypes.shape({}),
  isOpen: PropTypes.bool.isRequired,
};

CompanyUpdateModal.defaultProps = {
  company: {},
};
