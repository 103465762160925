import PropTypes from 'prop-types';
import React from 'react';
import { Card, Col, Icon, Row, Statistic } from 'antd';
import withDataIds from '../../containers/withDataIds';

function DevicesCard({ value }) {
  const list = value || [];
  return (
    <Card>
      <Row gutter={16}>
        <Col span={12}>
          <Statistic title="Dispositivi Totali" value={list.length} prefix={<Icon type="api" />} />
        </Col>
        <Col span={12}>
          <Statistic
            title="Online"
            value={list.filter(d => d.connectionStatus === 'online').length}
            prefix={<Icon type="cloud" theme="twoTone" twoToneColor="#52c41a" />}
          />
        </Col>
      </Row>
    </Card>
  );
}

export default withDataIds(DevicesCard);

DevicesCard.propTypes = {
  value: PropTypes.arrayOf(
    PropTypes.shape({
      lastCheckIn: PropTypes.shape({
        seconds: PropTypes.number,
        toMillis: PropTypes.func,
      }),
    }),
  ),
};

DevicesCard.defaultProps = {
  value: [],
};
