import { useCallback } from 'react';
import { notification } from 'antd';
import { useHistory } from 'react-router-dom';
import firebase from '../config';

export default function useDeleteCompany(companyId) {
  const history = useHistory();
  return useCallback(async () => {
    try {
      await firebase
        .firestore()
        .collection('companies')
        .doc(companyId)
        .delete();
      history.goBack();
      notification.success({ message: 'Azienda eliminata' });
    } catch (e) {
      console.log(e);
      notification.error({ message: e.message });
    }
  }, [companyId, history]);
}
