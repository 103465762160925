import PropTypes from 'prop-types';
import React from 'react';
import { Card, Col, Row, Statistic } from 'antd';

export default function UsersCard({ value }) {
  const list = value || [];
  return (
    <Row gutter={16} style={{ padding: '2rem 0' }}>
      <Col span={12}>
        <Card>
          <Statistic title="Utenti Totali" value={list.length} />
        </Card>
      </Col>
    </Row>
  );
}

UsersCard.propTypes = {
  value: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};
