export const ADMIN = 'admin';
export const TECHNICIAN = 'technician';
export const ROLE_OPTIONS = [
  { value: ADMIN, label: 'Amministratore' },
  { value: TECHNICIAN, label: 'Tecnico' },
];

export const EXCLUDE_DEVICES_FIELDS = ['__id', 'authorizedUsers'];

export const COLLECTION_DISPLAY_MAPPER = {
  companies: 'name',
  users: 'email',
};

export const FUNCTIONS_ENDPOINT = 'https://europe-west1-tlab-iot.cloudfunctions.net';

export const LOAD_START_ACTION_HOOK = 'startLoading';
export const LOAD_END_ACTION_HOOK = 'endLoading';

export const TOPICS = ['System', 'Marketing', 'Service'];

export const MAC_ADDR_PREFIX = 'F8F005';
