import PropTypes from 'prop-types';
import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import useFirebaseAuth from '../hooks/useFirebaseAuth';
import SpinnerPage from './SpinnerPage';

function AuthedRoute(props) {
  const [authed, loading] = useFirebaseAuth();

  if (loading) {
    return <SpinnerPage />;
  }

  if (authed) {
    return <Route {...props} />;
  }

  return (
    <Route
      {...props}
      component={() => (
        <Redirect
          to={`/login?next=${props.location.pathname}${encodeURIComponent(props.location.search)}`}
        />
      )}
    />
  );
}

export default AuthedRoute;

AuthedRoute.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }),
};

AuthedRoute.defaultProps = {
  location: null,
};
