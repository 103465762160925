import PropTypes from 'prop-types';
import { Button, Form, Modal, Select, Spin } from 'antd';
import { FirestoreCollection } from '@react-firebase/firestore';
import React, { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import withDataIds from '../../containers/withDataIds';
import { ROLE_OPTIONS } from '../../constants';
import useAddUserToCompany from '../../hooks/useAddUserToCompany';

function AddUserForm({ form, onSubmit, loading, value, companyId, close }) {
  const handleSubmit = useCallback(
    e => {
      e.preventDefault();
      form.validateFields((err, values) => {
        if (!err) {
          onSubmit(values.user, companyId, values.role);
          close();
        }
      });
    },
    [form, onSubmit],
  );

  const { getFieldDecorator } = form;
  const formItemLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 14 },
  };
  return (
    <Form {...formItemLayout} onSubmit={handleSubmit}>
      <Form.Item label="Utente">
        {getFieldDecorator('user', {
          rules: [{ required: true }],
        })(
          <Select showSearch optionFilterProp="children">
            {value.map(u => (
              <Select.Option key={u.id} value={u.id}>
                {u.email}
              </Select.Option>
            ))}
          </Select>,
        )}
      </Form.Item>

      <Form.Item label="Ruolo">
        {getFieldDecorator('role', {
          rules: [{ required: true }],
        })(
          <Select>
            {ROLE_OPTIONS.map(u => (
              <Select.Option key={u.value} value={u.value}>
                {u.label}
              </Select.Option>
            ))}
          </Select>,
        )}
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" loading={loading}>
          Aggiungi
        </Button>
      </Form.Item>
    </Form>
  );
}

const AddUserFormWrapper = withDataIds(Form.create()(AddUserForm));

export default function CompanyAddUserModal({ isOpen, close }) {
  const onSubmit = useAddUserToCompany();
  const { companyId } = useParams();
  return (
    <Modal visible={isOpen} title="Title" onCancel={close} footer={null}>
      <FirestoreCollection path="/users">
        {d =>
          d.isLoading ? (
            <Spin />
          ) : (
            <AddUserFormWrapper {...d} companyId={companyId} onSubmit={onSubmit} close={close} />
          )
        }
      </FirestoreCollection>
    </Modal>
  );
}

CompanyAddUserModal.propTypes = {
  close: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
};

CompanyAddUserModal.defaultProps = {
  isOpen: false,
};

AddUserForm.propTypes = {
  form: PropTypes.shape({
    validateFields: PropTypes.func,
    getFieldDecorator: PropTypes.func,
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  close: PropTypes.func.isRequired,
  value: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    }),
  ),
  companyId: PropTypes.string.isRequired,
};

AddUserForm.defaultProps = {
  loading: false,
  value: [],
};
