import { useEffect } from 'react';
import useFirestore from './useFirestore';
import useDataLoadingState, { LOAD_END_ACTION_HOOK } from './useDataLoadingStateNew';

export default function useCollectionWhere(collectionName, basePath, where, orderBy) {
  const [store, dispatch] = useDataLoadingState();
  const firestore = useFirestore();

  const base = basePath || firestore();

  let ref = base.collection(collectionName);
  const wheres = Array.isArray(where) ? where : [where];
  wheres.forEach(({ field, op, value }) => {
    ref = ref.where(field, op, value);
  });

  if (orderBy && Array.isArray(orderBy)) {
    if (Array.isArray(orderBy[0])) {
      orderBy.forEach(o => {
        ref = ref.orderBy(...o);
      });
    } else {
      ref = ref.orderBy(...orderBy);
    }
  }

  useEffect(
    () =>
      ref.onSnapshot(qs => {
        dispatch({
          type: LOAD_END_ACTION_HOOK,
          data: qs.docs.map(d => ({ id: d.id, __ref: d.ref, ...d.data() })),
        });
      }),
    [where, orderBy],
  );
  return store;
}
