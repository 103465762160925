import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { Form, Button, Upload, Icon, Input } from 'antd';

function FirmwareUploadForm({ form, onSubmit, loading }) {
  const handleSubmit = useCallback(
    e => {
      e.preventDefault();
      form.validateFields((err, values) => {
        if (!err) {
          onSubmit(values);
        }
      });
    },
    [form, onSubmit],
  );

  const normFile = useCallback(e => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList.slice(-1);
  }, []);

  const { getFieldDecorator } = form;
  const formItemLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 14 },
  };
  return (
    <Form {...formItemLayout} onSubmit={handleSubmit}>
      <Form.Item label="Modello">
        {getFieldDecorator('boardModel', {
          initialValue: 'tidomo_3in4out',
          rules: [{ required: true, message: 'Inserisci un modello' }],
        })(<Input />)}
      </Form.Item>
      <Form.Item label="Versione" extra="Inserisci una versione in formato semver">
        {getFieldDecorator('versionNumber', {
          rules: [
            {
              required: true,
              message: 'Inserisci una versione',
            },
          ],
        })(<Input placeholder="1.0.0" />)}
      </Form.Item>
      <Form.Item label="Versione Minima" extra="Inserisci una versione in formato semver">
        {getFieldDecorator('minPrevVersion', {
          rules: [
            {
              required: true,
              message: 'Inserisci una versione',
            },
          ],
        })(<Input placeholder="1.0.0" />)}
      </Form.Item>
      <Form.Item label="Note">{getFieldDecorator('notes')(<Input.TextArea rows={4} />)}</Form.Item>
      <Form.Item label="Firmware file">
        {getFieldDecorator('dragger', {
          valuePropName: 'fileList',
          getValueFromEvent: normFile,
          rules: [{ required: true, message: 'Devi caricare un file' }],
        })(
          <Upload.Dragger name="files" multiple={false} beforeUpload={() => false}>
            <p className="ant-upload-drag-icon">
              <Icon type="inbox" />
            </p>
            <p className="ant-upload-text">Clicca o trascina per caricare</p>
          </Upload.Dragger>,
        )}
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" loading={loading}>
          Invia
        </Button>
      </Form.Item>
    </Form>
  );
}

export default Form.create()(FirmwareUploadForm);

FirmwareUploadForm.propTypes = {
  form: PropTypes.shape({
    validateFields: PropTypes.func,
    getFieldDecorator: PropTypes.func,
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};
