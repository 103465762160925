import React, { useContext } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import SuperAdminRoute from '../components/SuperAdminRoute';
import AdminLayout from '../sections/admin/Layout';
import PermissionsContext from '../contexts/Permissions';
import IsSuperAdminContext from '../contexts/IsSuperAdmin';
import SpinnerPage from '../components/SpinnerPage';
import TechnicianHomePage from '../sections/technician';
import ClientHomePage from '../sections/client';
import AuthorizeIFTT from './AuthorizePage/AuthorizeIFTT';
import AuthorizeAlexa from './AuthorizePage/AuthorizeAlexa';
import AuthorizeGA from './AuthorizePage/AuthorizeGA';
import AdminDomainRoute from '../components/AdminDomainRoute';

export default function Home() {
  const permissions = useContext(PermissionsContext);
  const isSuper = useContext(IsSuperAdminContext);

  if (permissions === null || isSuper === null) {
    return <SpinnerPage />;
  }

  return (
    <Switch>
      <Route path="/authorizeIFTTT" component={AuthorizeIFTT} />
      <Route path="/authorizeAlexa" component={AuthorizeAlexa} />
      <Route path="/authorizeGoogleHome" component={AuthorizeGA} />
      <Route path="/client" component={ClientHomePage} />
      <AdminDomainRoute path="/app" component={TechnicianHomePage} />
      <SuperAdminRoute
        path="/admin"
        render={props => <AdminDomainRoute {...props} component={AdminLayout} />}
      />
      <Route
        exact
        path="/"
        render={() => {
          if (isSuper) {
            return <Redirect to="/admin" />;
          }
          if (permissions && permissions.length) {
            return <Redirect to="/app" />;
          }
          return <Redirect to="/client" />;
        }}
      />
      <Route path="" render={() => <Redirect to="/" />} />
    </Switch>
  );
}
