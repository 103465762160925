import PropTypes from 'prop-types';
import { Button, Form, Input, Modal, Select } from 'antd';
import React, { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import withDataIds from '../../containers/withDataIds';
import { ROLE_OPTIONS } from '../../constants';
import useInviteUser from '../../hooks/useInviteUser';

function InviteUserForm({ form, onSubmit, loading, companyId, close }) {
  const handleSubmit = useCallback(
    e => {
      e.preventDefault();
      form.validateFields(async (err, values) => {
        if (!err) {
          await onSubmit(values.user, companyId, values.role);
          close();
        }
      });
    },
    [form, onSubmit],
  );

  const { getFieldDecorator } = form;
  const formItemLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 14 },
  };
  return (
    <Form {...formItemLayout} onSubmit={handleSubmit}>
      <Form.Item label="Email">
        {getFieldDecorator('user', {
          rules: [{ required: true }],
        })(<Input type="email" />)}
      </Form.Item>

      <Form.Item label="Ruolo">
        {getFieldDecorator('role', {
          rules: [{ required: true }],
        })(
          <Select>
            {ROLE_OPTIONS.map(u => (
              <Select.Option key={u.value} value={u.value}>
                {u.label}
              </Select.Option>
            ))}
          </Select>,
        )}
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" loading={loading}>
          Invia invito
        </Button>
      </Form.Item>
    </Form>
  );
}

const AddUserFormWrapper = withDataIds(Form.create()(InviteUserForm));

export default function CompanyInviteUserModal({ isOpen, close }) {
  const [loading, onSubmit] = useInviteUser();
  const { companyId } = useParams();
  return (
    <Modal visible={isOpen} title="Invita utente" onCancel={close} footer={null}>
      <p>
        <strong>Attenzione:</strong> Se non esiste nessun utente associato a tale indirizzo email
        non verrà generato alcun invito.
      </p>
      <AddUserFormWrapper
        companyId={companyId}
        onSubmit={onSubmit}
        close={close}
        loading={loading}
      />
    </Modal>
  );
}

CompanyInviteUserModal.propTypes = {
  close: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
};

CompanyInviteUserModal.defaultProps = {
  isOpen: false,
};

InviteUserForm.propTypes = {
  form: PropTypes.shape({
    validateFields: PropTypes.func,
    getFieldDecorator: PropTypes.func,
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  close: PropTypes.func.isRequired,
  value: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    }),
  ),
  companyId: PropTypes.string.isRequired,
};

InviteUserForm.defaultProps = {
  loading: false,
  value: [],
};
