import React, { useCallback } from 'react';
import { Button, Descriptions, PageHeader, Popconfirm, Spin } from 'antd';
import { useParams, useHistory } from 'react-router-dom';

import { EXCLUDE_DEVICES_FIELDS } from '../../constants';
import formatter from '../../utils/formatter';
import useResetDevice from '../../hooks/useResetDevice';
import useModal from '../../hooks/useModal';
import DeviceUpdateFirmwareModal from './DeviceUpdateFirmwareModal';
import DeviceCloneModal from './DeviceCloneModal';
import useDocument from '../../hooks/useDocument';

export default function DeviceData() {
  const { deviceId } = useParams();

  const history = useHistory();

  const goBack = useCallback(() => history.goBack(), [history]);

  const resetDevice = useResetDevice(deviceId);
  const [updateModal, { open: openUpdate, close: closeUpdate }] = useModal();
  const [cloneModal, { open: openClone, close: closeClone }] = useModal();

  const { data: value, loading } = useDocument(deviceId, 'devices');

  if (loading) return <Spin />;
  return (
    <>
      <PageHeader title={`Dispositivo: ${value.label}`} onBack={goBack} />
      <Descriptions>
        {Object.keys(value)
          .filter(k => !EXCLUDE_DEVICES_FIELDS.includes(k))
          .map(k => (
            <Descriptions.Item key={k} label={k}>
              {value[k] && formatter(value[k])}
            </Descriptions.Item>
          ))}
      </Descriptions>
      <div className="mt-5">
        <Button className="mr-2" onClick={openClone}>
          Clona
        </Button>
        <Button className="mr-2" onClick={openUpdate}>
          Aggiorna
        </Button>
        <Popconfirm
          title="Sei sicuro di voler resettare il dispositivo?"
          onConfirm={resetDevice}
          cancelText="Annulla"
          okText="Conferma"
        >
          <Button className="mr-2" type="danger">
            Reset
          </Button>
        </Popconfirm>
      </div>
      <DeviceUpdateFirmwareModal isOpen={updateModal} close={closeUpdate} />
      <DeviceCloneModal close={closeClone} isOpen={cloneModal} />
    </>
  );
}
