export default function whereDeviceIsOwner(firebase, owner) {
  const ownerRef = firebase
    .firestore()
    .collection('users')
    .doc(owner);
  return {
    field: 'owner',
    op: '==',
    value: ownerRef,
  };
}
