import React, { useContext } from 'react';
import { Redirect, Route } from 'react-router-dom';
import IsSuperAdminContext from '../contexts/IsSuperAdmin';

function SuperAdminRoute(props) {
  const isSuper = useContext(IsSuperAdminContext);

  if (isSuper) {
    return <Route {...props} />;
  }

  return <Route {...props} component={() => <Redirect to="/" />} />;
}

export default SuperAdminRoute;
