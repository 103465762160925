import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { auth } from '../config';

export default function useLogout() {
  const history = useHistory();
  const logout = useCallback(async () => {
    history.replace('/login');
    auth().signOut();
  }, [history]);

  return logout;
}
