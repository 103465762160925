import { compose, withProps } from 'recompose';

const enhance = compose(
  withProps(({ value, ids }) => {
    if (value === null || typeof value === 'undefined') return null;
    return {
      value: value.map((val, i) => ({
        ...val,
        id: ids[i],
      })),
    };
  }),
);

export default enhance;
