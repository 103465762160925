import { useEffect, useState } from 'react';
import { auth } from '../config';

export default function useFirebaseAuth() {
  const [{ authed, loading }, setState] = useState({
    authed: false,
    loading: true,
  });

  useEffect(
    () =>
      auth().onAuthStateChanged(user => {
        if (user) {
          setState({
            authed: true,
            loading: false,
          });
        } else {
          setState({
            authed: false,
            loading: false,
          });
        }
      }),
    [],
  );

  return [authed, loading];
}
