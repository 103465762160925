import React from 'react';
import { Switch, Route } from 'react-router-dom';
import UserDetail from './UserDetail';
import UsersListPage from './UsersListPage';

export default function UsersPage() {
  return (
    <Switch>
      <Route path="/admin/users/" exact component={UsersListPage} />
      <Route path="/admin/users/:userId" component={UserDetail} />
    </Switch>
  );
}
