import { useCallback } from 'react';
import { notification } from 'antd';
import callHttpsFunction from '../utils/functions';
import emptyFn from '../utils/emptyFn';

export default function useCloneDevice(deviceId, dstDeviceId, onStart = emptyFn) {
  return useCallback(async () => {
    try {
      notification.info({ message: 'La procedura di clonazione del dispositivo è iniziata' });
      onStart();
      await callHttpsFunction('configCloneToDevice', {
        srcDeviceId: deviceId,
        dstDeviceId,
      });
      notification.success({ message: 'Il dispositivo è stato clonatoo' });
    } catch (e) {
      notification.error({ message: e.message });
    }
  }, [deviceId, dstDeviceId]);
}
