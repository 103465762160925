import { useCallback } from 'react';
import { notification } from 'antd';
import firebase from '../config';

export default function useRemoveUserFromCompany() {
  return useCallback(async (userId, path) => {
    try {
      await firebase
        .firestore()
        .collection('users')
        .doc(userId)
        .collection('userCompanyMembership')
        .doc(path)
        .delete();
    } catch (e) {
      console.log(e);
      notification.error({ message: e.message });
    }
  }, []);
}
