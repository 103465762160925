import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { auth } from '../config';

const IsSuperAdminContext = React.createContext(false);

export const IsSuperAdminProvider = ({ children }) => {
  const [isSuperAdmin, setState] = useState(null);

  useEffect(
    () =>
      auth().onAuthStateChanged(user => {
        if (user) {
          const admins = process.env.REACT_APP_SUPERADMINS.split(',');
          setState(admins.includes(user.email));
        }
      }),
    [],
  );

  return (
    <IsSuperAdminContext.Provider value={isSuperAdmin}>{children}</IsSuperAdminContext.Provider>
  );
};
export const IsSuperAdminConsumer = IsSuperAdminContext.Consumer;

export default IsSuperAdminContext;

IsSuperAdminProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
