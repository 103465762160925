import PropTypes from 'prop-types';
import React from 'react';
import {
  Avatar,
  Button,
  Descriptions,
  Icon,
  List,
  PageHeader,
  Popconfirm,
  Skeleton,
  Spin,
  Typography,
} from 'antd';
import { FirestoreDocument } from '@react-firebase/firestore';
import useUsersInCompany from '../../hooks/useUsersInCompany';
import { IsNotSuperAdmin, IsSuperAdmin } from '../../components/IsSuperAdmin';
import useChangeUserPermission from '../../hooks/useChangeUserPermission';
import useModal from '../../hooks/useModal';
import CompanyAddUserModal from './CompanyAddUserModal';
import { ADMIN, TECHNICIAN } from '../../constants';
import useRemoveUserFromCompany from '../../hooks/useRemoveUserFromCompany';
import CompanyUpdateModal from './CompanyUpdateModal';
import useDeleteCompany from '../../hooks/useDeleteCompany';
import CompanyInviteUserModal from './CompanyInviteUserModal';

const CompanyData = ({ value, goBack }) => {
  let companyRef = null;
  if (value && value.__id) {
    companyRef = value.__id;
  }

  const { data: users, loading } = useUsersInCompany(companyRef);

  const changeUserPermission = useChangeUserPermission();
  const removeUser = useRemoveUserFromCompany();

  const [isOpen, { open, close }] = useModal();
  const [editIsOpen, { open: openEdit, close: closeEdit }] = useModal();
  const deleteCompany = useDeleteCompany(companyRef);
  const [iniviteOpen, { open: openInvite, close: closeInvite }] = useModal();

  if (!value) return null;

  return (
    <>
      <PageHeader
        title={`Azienda: ${value.name}`}
        onBack={goBack}
        extra={[
          <IsNotSuperAdmin key="1">
            <Button onClick={openInvite}>Aggiungi tecnico</Button>
          </IsNotSuperAdmin>,
          <IsSuperAdmin key="2">
            <Button onClick={openEdit}>Modifica dati</Button>
          </IsSuperAdmin>,
          <IsSuperAdmin key="3">
            <Popconfirm
              title="Sei sicuro di voler cancellare?"
              okText="Si"
              okType="danger"
              cancelText="Annulla"
              icon={<Icon type="warning" />}
              placement="bottomRight"
              onConfirm={deleteCompany}
            >
              <Button type="danger">Elimina</Button>
            </Popconfirm>
          </IsSuperAdmin>,
        ]}
      />
      <Descriptions>
        {Object.keys(value).map(k => (
          <Descriptions.Item key={k} label={k}>
            {value[k] && value[k].toString()}
          </Descriptions.Item>
        ))}
      </Descriptions>
      <Typography.Title level={4}>Membri dell&apos;azienda</Typography.Title>
      <List
        loading={loading}
        dataSource={users}
        itemLayout="horizontal"
        renderItem={item => (
          <List.Item
            key={item.id}
            actions={[
              <IsSuperAdmin key="1">
                <Button
                  onClick={() =>
                    changeUserPermission(
                      item.id,
                      item.path,
                      item.role === ADMIN ? TECHNICIAN : ADMIN,
                    )
                  }
                >
                  rendi {item.role === ADMIN ? ' Tecnico' : ' Amministratore'}
                </Button>
              </IsSuperAdmin>,
              <IsSuperAdmin key="2">
                <Button type="danger" onClick={() => removeUser(item.id, item.path)}>
                  Rimuovi
                </Button>
              </IsSuperAdmin>,
            ]}
          >
            <Skeleton avatar title={false} active loading={item.loading}>
              <List.Item.Meta
                title={item.email}
                description={
                  item.role === ADMIN ? (
                    <>
                      <Icon type="crown" /> admin
                    </>
                  ) : (
                    <>
                      <Icon type="tool" /> tecnico
                    </>
                  )
                }
                avatar={<Avatar>{item.email.charAt(0)}</Avatar>}
              />
            </Skeleton>
          </List.Item>
        )}
      />
      <IsSuperAdmin>
        <Button className="mt-5" type="primary" onClick={open}>
          Aggiungi Utente
        </Button>
      </IsSuperAdmin>
      <CompanyAddUserModal isOpen={isOpen} close={close} />
      <CompanyUpdateModal isOpen={editIsOpen} close={closeEdit} />
      <CompanyInviteUserModal close={closeInvite} isOpen={iniviteOpen} />
    </>
  );
};

CompanyData.whyDidYouRender = true;

export default function CompanyDetail({ goBack, companyId }) {
  return (
    <FirestoreDocument path={`/companies/${companyId}`}>
      {d => (d.isLoading ? <Spin /> : <CompanyData {...d} goBack={goBack} />)}
    </FirestoreDocument>
  );
}

CompanyData.propTypes = {
  goBack: PropTypes.func.isRequired,
  value: PropTypes.shape(),
};

CompanyData.defaultProps = {
  value: null,
};

CompanyDetail.propTypes = {
  goBack: PropTypes.func.isRequired,
  companyId: PropTypes.string.isRequired,
};
