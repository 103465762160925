import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Spin } from 'antd';
import { COLLECTION_DISPLAY_MAPPER } from '../constants';
import firebase from '../config';

export default function AsyncReference({ reference }) {
  const [state, setState] = useState(null);
  useEffect(() => {
    async function getReference() {
      const doc = await reference.get();
      setState(doc.data()[COLLECTION_DISPLAY_MAPPER[reference.parent.id]]);
    }

    getReference();
  }, [setState, reference]);

  if (!state) {
    return <Spin />;
  }

  return state;
}

AsyncReference.propTypes = {
  reference: PropTypes.instanceOf(firebase.firestore.DocumentReference),
};
