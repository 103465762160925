import React from 'react';
import AsyncReference from '../components/AsyncReference';
import firebase from '../config';

export default function formatter(data) {
  if (typeof data !== 'object') {
    return data.toString();
  }

  if (data instanceof firebase.firestore.DocumentReference) {
    return <AsyncReference reference={data} />;
  }

  if (data instanceof firebase.firestore.Timestamp) {
    return data.toDate().toLocaleString();
  }

  return 'oggetto';
}
