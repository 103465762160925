import React from 'react';
import { FirebaseAuthProvider } from '@react-firebase/auth';
import { FirestoreProvider } from '@react-firebase/firestore';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import firebase from 'firebase';
import { firebaseConfig } from './config';
import LoginPage from './pages/Login';
import AuthedRoute from './components/AuthedRoute';
import { IsSuperAdminProvider } from './contexts/IsSuperAdmin';
import HomeWrapper from './pages/HomeWrapper';

function App() {
  return (
    <FirebaseAuthProvider firebase={firebase} {...firebaseConfig}>
      <FirestoreProvider firebase={firebase} {...firebaseConfig}>
        <IsSuperAdminProvider>
          <BrowserRouter>
            <Switch>
              <Route path="/login" component={LoginPage} />
              <AuthedRoute path="/" name="Home" component={HomeWrapper} />
              <Route component={() => <Redirect to="/" />} />
            </Switch>
          </BrowserRouter>
        </IsSuperAdminProvider>
      </FirestoreProvider>
    </FirebaseAuthProvider>
  );
}

export default App;
