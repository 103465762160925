import React from 'react';
import { PermissionProvider } from '../contexts/Permissions';
import Home from './Home';

export default function HomeWrapper() {
  return (
    <PermissionProvider>
      <Home />
    </PermissionProvider>
  );
}
