import PropTypes from 'prop-types';
import React from 'react';
import { Descriptions } from 'antd';
import formatter from '../utils/formatter';

export default function ObjectDescription({ object, excludeList }) {
  return (
    <Descriptions>
      {Object.keys(object)
        .filter(k => !excludeList.includes(k))
        .map(k => (
          <Descriptions.Item key={k} label={k}>
            {object[k] && formatter(object[k])}
          </Descriptions.Item>
        ))}
    </Descriptions>
  );
}

ObjectDescription.propTypes = {
  excludeList: PropTypes.arrayOf(PropTypes.string),
  object: PropTypes.shape().isRequired,
};

ObjectDescription.defaultProps = {
  excludeList: [],
};
