import { useCallback } from 'react';
import { notification } from 'antd';
import callHttpsFunction from '../utils/functions';

export default function useResetDevice(deviceId) {
  return useCallback(async () => {
    try {
      notification.info({ message: 'La procedura di reset del dispositivo è iniziata' });
      await callHttpsFunction('deviceFactoryResetAndDelete', {
        deviceId,
      });
      notification.success({ message: 'Il dispositivo è stato resettato' });
    } catch (e) {
      notification.error({ message: e.message });
    }
  }, [deviceId]);
}
