import React from 'react';
import { Card, Layout, Typography } from 'antd';

export default function ClientHomePage() {
  return (
    <Layout style={{ height: '100vh' }}>
      <Layout.Content style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Card style={{ maxWidth: '40em', margin: 20, textAlign: 'center' }}>
          <Typography.Title level={3}>Accesso bloccato</Typography.Title>
          <p>L&apos;accesso per i clienti non è attualmente permesso</p>
        </Card>
      </Layout.Content>
    </Layout>
  );
}
