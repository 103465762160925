import React from 'react';
import { Switch, Route } from 'react-router-dom';
import DeviceDetail from './DeviceDetail';
import DeviceListPage from './DeviceListPage';

export default function DevicePage() {
  return (
    <Switch>
      <Route path="/admin/devices/:deviceId" component={DeviceDetail} />
      <Route path="/admin/devices/" exact component={DeviceListPage} />
      <Route path="/app/:companyId/devices/" exact component={DeviceListPage} />
      <Route path="/app/:companyId/devices/:deviceId" component={DeviceDetail} />
    </Switch>
  );
}
