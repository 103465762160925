import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { Form, Button, Input } from 'antd';

function CreateCompanyForm({ form, onSubmit, loading }) {
  const handleSubmit = useCallback(
    e => {
      e.preventDefault();
      form.validateFields((err, values) => {
        if (!err) {
          onSubmit(values);
        }
      });
    },
    [form, onSubmit],
  );

  const { getFieldDecorator } = form;
  const formItemLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 14 },
  };
  return (
    <Form {...formItemLayout} onSubmit={handleSubmit}>
      <Form.Item label="Nome">
        {getFieldDecorator('name', {
          rules: [{ required: true }],
        })(<Input />)}
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" loading={loading}>
          Crea
        </Button>
      </Form.Item>
    </Form>
  );
}

export default Form.create()(CreateCompanyForm);

CreateCompanyForm.propTypes = {
  form: PropTypes.shape({
    validateFields: PropTypes.func,
    getFieldDecorator: PropTypes.func,
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};
