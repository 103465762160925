import React, { useCallback, useContext } from 'react';
import { Switch, Route, Link, useParams, useHistory } from 'react-router-dom';
import { Icon, Menu, Radio, Typography } from 'antd';
import find from 'lodash/find';
import DefaultLayout from '../../components/Layout';
import DashboardPage from '../../pages/Dashboard';
import DevicePage from '../../pages/Devices';
import CompaniesPage from '../../pages/Companies';
import PermissionsContext from '../../contexts/Permissions';
import { ADMIN } from '../../constants';

export default function TechnicianLayout() {
  const { companyId } = useParams();
  const permissions = useContext(PermissionsContext);
  const history = useHistory();
  const changeCompany = useCallback(
    e => {
      history.push(`/app/${e.target.value}`);
    },
    [history],
  );

  const isAdmin = find(permissions, p => p.role === ADMIN && p.id === companyId);

  return (
    <DefaultLayout
      menuItems={[
        <Menu.Item key="1">
          <Icon type="dashboard" />
          <span>Dashboard</span>
          <Link to={`/app/${companyId}/`} />
        </Menu.Item>,
        <Menu.Item key="2-1">
          <Icon type="robot" />
          <span>Dispositivi</span>
          <Link to={`/app/${companyId}/devices/`} />
        </Menu.Item>,
        isAdmin && (
          <Menu.Item key="4">
            <Icon type="bank" />
            <span>Gestisci</span>
            <Link to={`/app/${companyId}/manage`} />
          </Menu.Item>
        ),
      ]}
      extraDrawer={
        permissions.length > 1 && (
          <>
            <div className="divider" />
            <Typography.Title level={4}>Seleziona azienda</Typography.Title>
            <Radio.Group value={companyId} onChange={changeCompany}>
              {permissions.map(p => (
                <Radio key={p.id} value={p.id}>
                  {p.name} <Icon type={p.role === ADMIN ? 'crown' : 'tool'} />
                </Radio>
              ))}
            </Radio.Group>
          </>
        )
      }
    >
      <Switch>
        <Route exact path="/app/:companyId/" component={DashboardPage} />
        <Route path="/app/:companyId/devices/" component={DevicePage} />
        {isAdmin && <Route path="/app/:companyId/manage" exact component={CompaniesPage} />}
      </Switch>
    </DefaultLayout>
  );
}
