import { useContext, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import find from 'lodash/find';
import PermissionsContext from '../contexts/Permissions';

export default function useCurrentCompany() {
  const { companyId } = useParams();
  const permissions = useContext(PermissionsContext);

  const currentCompany = useMemo(() => find(permissions, p => p.id === companyId), [
    permissions,
    companyId,
  ]);

  return currentCompany;
}
