import { useCallback, useState } from 'react';
import { notification } from 'antd';
import callHttpsFunction from '../utils/functions';

export default function useInviteUser() {
  const [state, setState] = useState(false);
  const invite = useCallback(async (email, company, role) => {
    try {
      setState(true);
      await callHttpsFunction('sendCompanyJoinInvite', {
        companyDocId: company,
        email,
        role,
      });
      notification.success({ message: 'Utente invitato' });
    } catch (e) {
      notification.error({ message: e.message });
    } finally {
      setState(false);
    }
  });

  return [state, invite];
}
