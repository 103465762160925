import PropTypes from 'prop-types';
import { Table } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import withDataIds from '../../containers/withDataIds';

function CompaniesList({ isLoading, value }) {
  const items = value || [];
  return (
    <Table
      loading={isLoading}
      dataSource={items}
      itemLayout="horizontal"
      columns={[
        {
          title: 'Nome',
          dataIndex: 'name',
        },
        {
          title: 'Azioni',
          render: (_, record) => (
            <span>
              <Link to={`${record.id}/`}>vedi Azienda</Link>
            </span>
          ),
        },
      ]}
    />
  );
}

export default withDataIds(CompaniesList);

CompaniesList.propTypes = {
  isLoading: PropTypes.bool,
  value: PropTypes.arrayOf(PropTypes.shape({})),
};

CompaniesList.defaultProps = {
  value: [],
  isLoading: true,
};
