import { useContext } from 'react';
import IsSuperAdminContext from '../contexts/IsSuperAdmin';

export function IsSuperAdmin({ children }) {
  const isSuper = useContext(IsSuperAdminContext);
  return isSuper ? children : null;
}

export function IsNotSuperAdmin({ children }) {
  const isSuper = useContext(IsSuperAdminContext);
  return !isSuper ? children : null;
}
